<template>
  <div class="flex flex-col py-4">
    <div>
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ collection.pagination.currentPageStart }}</span>
        to
        <span class="font-medium">{{ collection.pagination.currentPageEnd }}</span>
        of
        <span class="font-medium">{{ collection.pagination.totalCount }}</span>
        {{ pluralItemName }}
      </p>
    </div>
    <div class="flex flex-row space-x-2">
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white pagination-button"
        @click.prevent="collection.pagination.hasPrevPage && handlePrevPage()"
      >
        <ArrowLeft />
        Previous
      </a>
      <a
        href="#"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white pagination-button"
        @click.prevent="collection.pagination.hasNextPage && handleNextPage()"
      >
        Next
        <ArrowRight />
      </a>
    </div>
  </div>
</template>

<script>
import { CollectionManager } from '@thinknimble/tn-models'
import ArrowLeft from '@/components/icons/ArrowLeft'
import ArrowRight from '@/components/icons/ArrowRight'

export default {
  name: 'CollectionPagination',
  components: { ArrowLeft, ArrowRight },
  props: {
    collection: {
      type: CollectionManager,
      required: true,
    },
    pluralItemName: {
      type: String,
      default: 'results',
    },
  },
  methods: {
    async handlePrevPage() {
      this.$emit('is-loading-status-update', true)
      await this.collection.prevPage()
      this.$emit('is-loading-status-update', false)
    },
    async handleNextPage() {
      this.$emit('is-loading-status-update', true)
      await this.collection.nextPage()
      this.$emit('is-loading-status-update', false)
    },
  },
}
</script>

<style scoped>
.pagination-button {
  width: 7rem;
  display: flex;
  justify-content: center;
}
</style>
